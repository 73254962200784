import React, { Component, FC, PropsWithChildren, createContext } from 'react'
import { Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, styled } from '@mui/material'

interface IProps {
    onSubmit?: () => void
    onClose?: () => void
    content?: string
}

export const FormDelete: FC<IProps> = (props) => {
    return (
        <Card>
            <DialogTitle>Are you sure delete?</DialogTitle>
            <DialogContent>
                <div>{props.content}</div>
            </DialogContent>
            <DialogActions>
                <Button color="error" variant='contained' onClick={props.onSubmit} autoFocus>
                    yes
                </Button>
                <Button color="info" variant='outlined' onClick={props.onClose}>
                    no
                </Button>
            </DialogActions>
        </Card>
    )
}
