import React from 'react';
import './App.css';
import { styled } from '@mui/material';
import { Provider } from 'react-redux';
import { AlertGlobal } from './components/ApiAlertContext';
import store from './Modules/ClaimedVideo/Reduxes/ClaimedVideoStore';
import { ClaimedVideoContainer } from './Modules/ClaimedVideo/Reduxes/MapAction';
import GlobalModal from './components/GlobalModal';
import './index.css'
const CustomAlertGlobal = styled('div')({ '& > div': { top: '83px', right: '12px!important' } })

function App() {
  return (<React.Fragment>
    <GlobalModal>
      <Provider store={store}>
        <ClaimedVideoContainer />
      </Provider>
    </GlobalModal>
    <CustomAlertGlobal>
      <AlertGlobal />
    </CustomAlertGlobal>
  </React.Fragment>
  );
}

export default App;
