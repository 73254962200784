import { Component, ComponentType } from 'react'
import { connect, ConnectedComponent } from 'react-redux'
import { IReturnDispatch, TDispatchRedux, TStateRedux } from './type'
import { LazyView } from './LazyView'
import { LazyStatus } from '../../Modules/types'

interface hocComponentProp<TActionParam> {
  params?: TActionParam
}

interface OptionsHocLazy<TActionParam> {
  params?: TActionParam
}
export const CreateHocLazy = function <
  TActionParam = any,
  TMapState extends TStateRedux = TStateRedux,
  TMapDispatch extends TDispatchRedux<TActionParam> = TDispatchRedux<TActionParam>,
  TComponentProp = any,
>(
  WrappedComponent: ComponentType<any>,
  options: OptionsHocLazy<TActionParam> | null = null
) {
  type Props = hocComponentProp<TActionParam> & TMapState & TMapDispatch & TComponentProp
  class HocComponent extends Component<Props> {
    renderContent = () => {
      switch (this.props.status) {
        case LazyStatus.Loaded:
        case LazyStatus.Loading:
          return (
            <LazyView in={this.props.status === LazyStatus.Loading}>
              <WrappedComponent {...this.props} />
            </LazyView>
          )
        case LazyStatus.Error:
          return <div>Error...</div>
        default:
          return <div></div>
      }
    }

    componentDidMount = () => {
      const param = Object.assign({}, options?.params ?? {}, this.props.params ?? {})
      this.DispatchReturn = this.props.FetchData ? this.props.FetchData(param) : undefined
    }
    DispatchReturn?: IReturnDispatch
    componentWillUnmount() {
      this.DispatchReturn?.abort()
    }
    render() {
      return this.renderContent()
    }
  }

  return HocComponent as ComponentType<TComponentProp>
}
