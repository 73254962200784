import { createEntityAdapter, createSlice, EntityState, PayloadAction, Update } from '@reduxjs/toolkit'
import { ISliceBase, LazyStatus } from '../../types'
import { IClaimedVideoModel } from '../type'
import { fetchSliceThunk } from './Thunks'
import { ERoleType } from '../../../Utilities/Contants'

export interface ISliceStateRedux extends ISliceBase<IClaimedVideoModel> {
    tableLoading: boolean
    Role?: ERoleType
}

const SliceSliceAdapter = createEntityAdapter<IClaimedVideoModel>({ selectId: (x) => x.Id })

// Define the initial state using that type
const initialState: ISliceStateRedux = {
    status: LazyStatus.Loading,
    data: SliceSliceAdapter.getInitialState(),
    tableLoading: false,
}

export const ClaimedVideoSlice = createSlice({
    name: 'ClaimedVideoSlice',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        TableLoading: (state, action: PayloadAction<boolean>) => {
            state.tableLoading = action.payload
        },
        Update: (state, action: PayloadAction<Update<Partial<IClaimedVideoModel>>>) => {
            SliceSliceAdapter.updateOne(state.data as EntityState<IClaimedVideoModel>, action.payload)
        },
        UpdateRange: (state, action: PayloadAction<Update<Partial<IClaimedVideoModel>>[]>) => {
            SliceSliceAdapter.updateMany(state.data as EntityState<IClaimedVideoModel>, action.payload)
        },
        Add: (state, action: PayloadAction<IClaimedVideoModel>) => {
            SliceSliceAdapter.addOne(state.data as EntityState<IClaimedVideoModel>, action.payload)
        },
        Remove: (state, action: PayloadAction<string>) => {
            SliceSliceAdapter.removeOne(state.data as EntityState<IClaimedVideoModel>, action.payload)
        },
        RemoveAll: (state) => {
            SliceSliceAdapter.removeAll(state.data as EntityState<IClaimedVideoModel>)
        },
        UpsetMany: (state, action: PayloadAction<IClaimedVideoModel[]>) => {
            SliceSliceAdapter.upsertMany(state.data as EntityState<IClaimedVideoModel>, action.payload)
        },
        AddRole: (state, action: PayloadAction<ERoleType>) => {
            state.Role = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSliceThunk.fulfilled, (state, action) => {
                if (state.requestId !== action.meta.requestId) return
                SliceSliceAdapter.upsertMany(state.data, action.payload ?? [])
                state.status = LazyStatus.Loaded
            })
            .addCase(fetchSliceThunk.rejected, (state, action) => {
                if (state.requestId !== action.meta.requestId) return
                state.status = LazyStatus.Error
            })
            .addCase(fetchSliceThunk.pending, (state, action) => {
                state.requestId = action.meta.requestId
                state.status = LazyStatus.Loading
            })
    },
})
