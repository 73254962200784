import { IModelCombobox } from "../Modules/types"
import { ERoleType } from "../Utilities/Contants"
import { FetchDelay } from "../Utilities/helper"
import { CreateHttpService } from "./Getway"
import ServiceBase from "./ServiceBase"

class ComponentServices extends ServiceBase {
    constructor() {
        super(CreateHttpService(`/api-v1/component`))
    }
    fetchAllMediaNetwork = (value?: string, signal?: AbortSignal): Promise<IModelCombobox[]> => {
        return this.PushNotify(this.Get, "all_network?value=" + (value ?? ''), { signal })
    }
    fetchAllUser = (value?: string, signal?: AbortSignal): Promise<IModelCombobox[]> => {
        return this.PushNotify(this.Get, "all_user?value=" + (value ?? ''), { signal })
    }

    fetchRole = () => {
        return this.PushNotify(this.Get<ERoleType>, "get_role")
    }
}

export default new ComponentServices()