import { CreateHttpService } from "../../../Services/Getway";
import ServiceBase from "../../../Services/ServiceBase";
import { FetchDelay, Sleep } from "../../../Utilities/helper";
import { EClaimedVideoStatus, IClaimedVideoModel } from "../type";

class ClaimedVideoService extends ServiceBase {
    constructor() {
        super(CreateHttpService(`/api-v1/claimed_video`))
    }
    All = async (signal?: AbortSignal) => {
        return FetchDelay(() => {
            return this.PushNotify(this.Get, "all", { signal })
        }, 500)
    }
    Create = (model: IClaimedVideoModel, signal?: AbortSignal) => {
        return FetchDelay(() => {
            return this.PushNotify(this.Post, "create", model, {
                signal
            })
        }, 500)
    }
    Update = (Id: string, model: IClaimedVideoModel, signal?: AbortSignal) => {
        return FetchDelay(() => {
            return this.PushNotify(this.Put, "update" + "/" + Id, model, {
                signal
            })
        }, 500)
    }
    Remove = (Id: string, signal?: AbortSignal) => {
        return FetchDelay(() => {
            return this.PushNotify(this.Delete, "delete" + "/" + Id, {
                signal
            })
        }, 500)
    }
    UpdateStatus = async (Ids: string[], Status: EClaimedVideoStatus, signal?: AbortSignal) => {
        return FetchDelay(() => {
            return this.PushNotify(this.Post, "update_status", { Ids, Status }, {
                signal
            })
        }, 500)
    }

}

export default new ClaimedVideoService()