import React, { FC, PropsWithChildren, useCallback, useState } from "react";
import { ContextMenu, ContextMenuTrigger, hideMenu } from "react-contextmenu";
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import { Box, Divider, Grid, styled } from "@mui/material";



export interface IRenderMenuState { isShow: boolean, onHide: () => void, onShow: () => void }

interface ContextMenuTableProps {
    onClickMenu?: (status: string) => void
    renderMenu: (state: IRenderMenuState) => JSX.Element[]
    id?: string
}
export const ContextMenuTable: FC<PropsWithChildren<ContextMenuTableProps>> = (props) => {
    const handleClickLeft = useCallback<React.MouseEventHandler<HTMLElement>>((e) => {
        if (e.button === 0) {
            hideMenu()
        }
    }, [])
    const [isShow, setIsShow] = useState(false)
    const onHide = useCallback(() => {
        setIsShow(false)
    }, [])
    const onShow = useCallback(() => {
        setIsShow(true)
    }, [])
    return (
        <WrapGrid onClick={handleClickLeft}>
            {/* NOTICE: id must be unique between EVERY <ContextMenuTrigger> and <ContextMenu> pair */}
            {/* NOTICE: inside the pair, <ContextMenuTrigger> and <ContextMenu> must have the same id */}
            <ContextMenuTrigger {...({
                children: <>
                    {props.children}
                    <div style={{ display: isShow ? "flex" : "none", position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, zIndex: 99 }}></div>
                </>
            })} id={props.id ?? "same_unique_identifier"} holdToDisplay={-1} />
            <ContextMenu id={props.id ?? "same_unique_identifier"} style={{ zIndex: 100 }} onHide={onHide} onShow={onShow}
                {...({
                    children:
                        <Paper sx={{ width: 200, maxWidth: '100%' }}>
                            <MenuList>
                                {props.renderMenu({ isShow, onHide, onShow })}
                            </MenuList>
                        </Paper>
                })}
            />
        </WrapGrid >
    );
}

const WrapGrid = styled(Grid)({
    display: 'flex', flexDirection: 'column', padding: 0, '& .react-contextmenu-wrapper': {
        display: 'flex',
        flex: 1,
    },
    flex: 1,
})