import { Box, Divider, FormControl, Grid, IconButton, Typography, } from "@mui/material"
import { FC, PropsWithChildren } from "react"
import CloseIcon from '@mui/icons-material/Close'
import ClosedCaptionIcon from '@mui/icons-material/ClosedCaption'
import { GlobalModalContext } from "./GlobalModal"
import { GridProps } from "@mui/material/Grid"
import { styled } from "@mui/material/styles"
interface TGridProps extends GridProps { }
export const ControlBoxFull: FC<PropsWithChildren<TGridProps>> = (props: TGridProps) => {
    const { ...orther } = props
    return <Grid {...orther} >
        <FormControl fullWidth variant="outlined">
            {props.children}
        </FormControl>
    </Grid>
}
ControlBoxFull.defaultProps = {
    md: 12,
    item: true,
    xs: 12,
}


export const ControlBoxHalf: FC<PropsWithChildren<TGridProps>> = (props: TGridProps) => {
    const { ...orther } = props
    return <Grid {...orther} >
        <FormControl fullWidth variant="outlined">
            {props.children}
        </FormControl>
    </Grid>
}
ControlBoxHalf.defaultProps = {
    md: 6,
    item: true,
    xs: 12,
}
const BtnClose = styled(IconButton)({
    flex: '0 0 auto',
    color: '#3c3c3c',
    marginLeft: 'auto',
    '& svg': { transition: 'all 0.2s' },
    '&:hover svg': { color: '#ff200c' },
})
interface IFormHeaderProps {
    Title?: string
}
export const FormHeader: FC<IFormHeaderProps> = (props) => {
    return <Grid item xs={12}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px', paddingBottom: '6px' }}>
            <ClosedCaptionIcon color="info" />
            <Typography variant="h6">{props.Title ?? "Create"}</Typography>
            <GlobalModalContext.Consumer>
                {({ closeModal }) => (
                    <BtnClose onClick={closeModal}>
                        <CloseIcon sx={{ color: '#727272' }} />
                    </BtnClose>
                )}
            </GlobalModalContext.Consumer>
        </Box>
        <Divider sx={{ opacity: '0.12' }} />
    </Grid>
}
