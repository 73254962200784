import { configureStore } from '@reduxjs/toolkit'
import thunkMiddleware from 'redux-thunk'
import ClaimedVideoReducers from './ClaimedVideoReducers'
import { ClaimedVideoSlice } from './Slice'
import CompoentServices from '../../../Services/CompoentServices'
const store = configureStore({
    reducer: ClaimedVideoReducers,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([thunkMiddleware]),
})
const FetchRole = async () => {
    const role = await CompoentServices.fetchRole()
    store.dispatch(ClaimedVideoSlice.actions.AddRole(role))
}
FetchRole()
export default store
