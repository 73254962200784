import { TextField } from "@mui/material"
import { GridCellParams, GridFilterInputValueProps, GridFilterItem, GridFilterOperator } from "@mui/x-data-grid"
import { GridApiCommunity, GridStateColDef } from "@mui/x-data-grid/internals"
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import React, { FC, useCallback } from "react"


export const CreateUpsertFilterItem = (apiRef: GridApiCommunity) => {
    const upsertFilterItem = React.useCallback(
        (item: GridFilterItem) => {
            const gridState = apiRef.state
            if (item.id) {
                const index = gridState.filter.filterModel.items.findIndex((x) => x.id === item.id)
                if (index >= 0) {
                    gridState.filter.filterModel.items[index] = Object.assign({}, gridState.filter.filterModel.items[index], item)
                }
            } else {
                const field = item.field ? item.field : gridState.columns.orderedFields[0]
                const column = gridState.columns.lookup[field]
                const Temp = {
                    id: Math.round(1e5 * Math.random()),
                    columnField: field,
                    operatorValue: column.filterOperators ? column.filterOperators[0].value : ''
                }
                gridState.filter.filterModel.items.push(Object.assign({}, Temp, item))
            }
            apiRef.setFilterModel({ ...gridState.filter.filterModel, items: [...gridState.filter.filterModel.items] })
            const apiObject = apiRef as any
            const key_fun = Object.keys(apiObject).find((x) => /(_applyFilters)/.test(x)) ?? ''

            apiObject[key_fun]()
        },
        [apiRef]
    )
    return {
        upsertFilterItem
    }
}

export interface MaterialUIPickersProps {
    OnChange: (value: Date | null) => void
    value?: Date
}
const MaterialUIPickers: FC<MaterialUIPickersProps> = (props) => {

    const handleChange = (newValue: Date | null) => {
        props.OnChange(newValue)
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
                label="Value"
                inputFormat="MM/dd/yyyy"
                value={props.value || null}
                onChange={handleChange}
                renderInput={(params) => <TextField sx={{ margin: 0, maxWidth: '180px' }} variant="standard" {...params} />}
            />
        </LocalizationProvider>
    );
}
// export interface GridFilterOperator {
//     label?: string;
//     value: string;
//     getApplyFilterFn: (
//         filterItem: GridFilterItem,
//         column: GridStateColDef
//     ) => null | ((params: GridCellParams) => boolean);
//     InputComponent?: React.JSXElementConstructor<GridFilterInputValueProps>;
//     InputComponentProps?: Record<string, any>;
// }

export const DateTimeFilters: GridFilterOperator[] = [
    {
        label: "From",
        value: "From",
        getApplyFilterFn: (filterItem) => {
            if (
                !filterItem.field ||
                !filterItem.value ||
                !filterItem.operator
            ) {
                return null;
            }
            return (params: GridCellParams): boolean => {
                return new Date(new Date(params.value as string).toDateString()) >= new Date(new Date(filterItem.value).toDateString());
            };
        },
        InputComponent: (props: GridFilterInputValueProps) => {
            const Onchange = useCallback(
                (value: Date | null) => {

                    value && props.applyValue({ ...props.item, value });
                },
                [props]
            );
            return (
                <MaterialUIPickers value={props.item.value} OnChange={Onchange} />
            );
        },
    },
    {
        label: "To",
        value: "To",
        getApplyFilterFn: (filterItem) => {
            if (
                !filterItem.field ||
                !filterItem.value ||
                !filterItem.operator
            ) {
                return null;
            }
            return (params: GridCellParams): boolean => {
                return new Date(new Date(params.value as string).toDateString()) <= new Date(new Date(filterItem.value).toDateString());
            };
        },
        InputComponent: (props: GridFilterInputValueProps) => {
            const Onchange = useCallback(
                (value: Date | null) => {

                    value && props.applyValue({ ...props.item, value });
                },
                [props]
            );
            return (
                <MaterialUIPickers value={props.item.value} OnChange={Onchange} />
            );
        },
    },
    {
        label: "Equal",
        value: "Equal",
        getApplyFilterFn: (filterItem) => {
            if (
                !filterItem.field ||
                !filterItem.value ||
                !filterItem.operator
            ) {
                return null;
            }
            return (params: GridCellParams): boolean => {
                return new Date(new Date(params.value as string).toDateString()) === new Date(new Date(filterItem.value).toDateString());
            };
        },
        InputComponent: (props: GridFilterInputValueProps) => {
            const Onchange = useCallback(
                (value: Date | null) => {

                    value && props.applyValue({ ...props.item, value });
                },
                [props]
            );
            return (
                <MaterialUIPickers value={props.item.value} OnChange={Onchange} />
            );
        },
    },
];
