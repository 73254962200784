import React from 'react'
import { GridFilterItem, GridToolbar, GridToolbarProps, useGridApiContext } from '@mui/x-data-grid'
import { CreateUpsertFilterItem } from './Components'



export const CustomerGridToolbar: React.FC<GridToolbarProps> = (props) => {
  const apiRef = useGridApiContext()

  apiRef!.current.upsertFilterItem = CreateUpsertFilterItem(apiRef!.current).upsertFilterItem

  return <GridToolbar  {...props} {...{
    sx: {
      minHeight: '70px'
    }
  } as GridToolbarProps} />
}
