export enum EClaimedVideoStatus {
    New = "New", Pending = "Pending", Approved = "Approved", Warning = "Warning", Rejected = "Rejected"
}

export interface IClaimedVideoModel {
    Id: string
    Email: string
    Username: string
    UserId: string
    ClaimName: string
    VideoLink: string
    TimeStamp: string
    DateCreated: string
    Status: EClaimedVideoStatus
    NetworkId: string
    Network: string
    StartTime?: number
    EndTime?: number
}

export type ExtractObject<TValue = string> = { [key: string]: TValue };
