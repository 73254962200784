import { Box, Chip, SvgIcon, Typography, alpha } from "@mui/material";
import { EClaimedVideoStatus } from "../Modules/ClaimedVideo/type";
import { IOptionSelect, IPositionedMenuModel } from "../components/SubComponent";

import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import PendingIcon from "@mui/icons-material/Pending";
import WarningIcon from "@mui/icons-material/Warning";
import NewReleasesIcon from "@mui/icons-material/NewReleases";

export const ClaimedVideoStatusOption = Object.values(EClaimedVideoStatus).map<IOptionSelect>(x => ({ name: x, value: x }))


export const ClaimedVideoStatusColors: {
    [key: string]: {
        color: string,
        icon: typeof SvgIcon
    }
} = {}
ClaimedVideoStatusColors[EClaimedVideoStatus.New] = {
    color: "#008000",
    icon: NewReleasesIcon
};
ClaimedVideoStatusColors[EClaimedVideoStatus.Pending] = {
    color: "#1565c0",
    icon: PendingIcon
};
ClaimedVideoStatusColors[EClaimedVideoStatus.Approved] = {
    color: "#008000",
    icon: CheckBoxIcon
};
ClaimedVideoStatusColors[EClaimedVideoStatus.Warning] = {
    color: "#ed7400",
    icon: WarningIcon
};
ClaimedVideoStatusColors[EClaimedVideoStatus.Rejected] = {
    color: "#ff0000",
    icon: DoNotDisturbAltIcon
};
export const ClaimedVideoStatusMenus = Object.values(EClaimedVideoStatus).map<IPositionedMenuModel>(x =>
({
    name: <Box sx={{ border: '1px solid ' + alpha(ClaimedVideoStatusColors[x].color, 0.3), minWidth: '100px', padding: '3px', borderRadius: '5px', background: '#fafafa', display: 'flex', justifyContent: 'center' }}>
        <Typography sx={{ fontSize: '0.9rem', fontWeight: 'bold', color: ClaimedVideoStatusColors[x].color, textTransform: 'none' }}>{x}</Typography>
    </Box>, value: x
}))




export enum ERoleType {
    ADMIN = "ADMIN", USER = "USER", MANAGE = "MANAGE"
}