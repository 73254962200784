import { Box, Divider, FormControl, Grid, IconButton, Paper, Typography, } from "@mui/material";
import { Component } from "react";

import * as SubLocal from '../../../components/local-lib'
import * as SubCommon from '../../../components/SubComponent'
import { getErrorMessage, secToDate } from "../../../Utilities/helper";
import { EClaimedVideoStatus, IClaimedVideoModel } from "../type";
import { ControlBoxFull, ControlBoxHalf, FormHeader } from "../../../components/FormHelper";
import { IModelCombobox } from "../../types";
import { ClaimedVideoStatusOption, ERoleType } from "../../../Utilities/Contants";
import ComponentServices from '../../../Services/CompoentServices'
interface IProps extends SubCommon.FormBase<IClaimedVideoModel> {
    Actions?: JSX.Element
    Title?: string
}

const SelectNetwork = SubCommon.CreateSelect2<IModelCombobox>()
const SelectUser = SubCommon.CreateSelect2<IModelCombobox>()

export default class FormBase extends Component<IProps> {

    renderAdmin = () => {
        return <><ControlBoxFull>
            <SelectUser
                title="User"
                fetchData={ComponentServices.fetchAllUser}
                SelectValue={(m) => m.value}
                disabled={!!this.props.Model}
                GenerateLabel={(m) => m.name}
                selectedItem={{ value: '', name: '' }}
                isOptionEqualToValue={(m1, m2) => m1.value === m2.value}
                defaultValue={this.props.Model?.UserId}
                PopperStyle={{ zIndex: 6000 }}
                searchInitial={(model) => this.props.Model?.UserId?.toLowerCase() === model.value?.toLowerCase()}
                {...getErrorMessage(this.props.MessageError, 'UserId')}
                onBlur={() => this.props.onBlur && this.props.onBlur('UserId')}
                name="UserId"
            />
        </ControlBoxFull>
            <ControlBoxHalf>
                <SelectNetwork
                    title="Network"
                    fetchData={ComponentServices.fetchAllMediaNetwork}
                    SelectValue={(m) => m.value}
                    disabled={!!this.props.Model}
                    GenerateLabel={(m) => m.name}
                    selectedItem={{ value: '', name: '' }}
                    isOptionEqualToValue={(m1, m2) => m1.value === m2.value}
                    defaultValue={this.props.Model?.Network}
                    PopperStyle={{ zIndex: 6000 }}
                    searchInitial={(model) => this.props.Model?.NetworkId?.toLowerCase() === model.value?.toLowerCase()}
                    {...getErrorMessage(this.props.MessageError, 'NetworkId')}
                    onBlur={() => this.props.onBlur && this.props.onBlur('NetworkId')}
                    name="NetworkId"
                />
            </ControlBoxHalf>
            <ControlBoxHalf>
                <SubCommon.SmallSelect
                    data={ClaimedVideoStatusOption}
                    title="Status"
                    id="Status"
                    {...getErrorMessage(this.props.MessageError, 'Status')}
                    defaultValue={this.props.Model?.Status}
                />
            </ControlBoxHalf></>
    }

    renderUser = () => {
        return <>
            <ControlBoxFull>
                <SelectNetwork
                    title="Network"
                    fetchData={ComponentServices.fetchAllMediaNetwork}
                    SelectValue={(m) => m.value}
                    disabled={!!this.props.Model}
                    GenerateLabel={(m) => m.name}
                    selectedItem={{ value: '', name: '' }}
                    isOptionEqualToValue={(m1, m2) => m1.value === m2.value}
                    defaultValue={this.props.Model?.Network}
                    PopperStyle={{ zIndex: 6000 }}
                    searchInitial={(model) => this.props.Model?.NetworkId?.toLowerCase() === model.value?.toLowerCase()}
                    {...getErrorMessage(this.props.MessageError, 'NetworkId')}
                    onBlur={() => this.props.onBlur && this.props.onBlur('NetworkId')}
                    name="NetworkId"
                />
            </ControlBoxFull>
        </>
    }


    renderRoleField = () => {
        switch (this.props.Role) {
            case ERoleType.ADMIN:
                return this.renderAdmin()
            case ERoleType.MANAGE:
            case ERoleType.USER:
                return this.renderUser()
            default:
                return <></>
        }
    }
    render() {
        return (
            <Paper sx={{
                p: '12px 12px 18px', flex: 1, display: 'flex',
                minWidth: {
                    xs: '300px',
                }
            }} >
                <input hidden defaultValue={this.props.Model?.Id} name="id" />
                <Grid container spacing={2}>
                    <FormHeader Title={this.props.Title} />
                    {this.renderRoleField()}
                    <ControlBoxHalf>
                        <SubLocal.InputOutline
                            MaxLength={500}
                            Title="Claim name"
                            maxRows={1}
                            minRows={1}
                            onBlur={() => this.props.onBlur && this.props.onBlur('ClaimName')}
                            {...getErrorMessage(this.props.MessageError, 'ClaimName')}
                            inputProps={{ multiline: false, name: 'ClaimName' }}
                            defaultValue={this.props.Model?.ClaimName}
                        />
                    </ControlBoxHalf>
                    <ControlBoxHalf>
                        <SubLocal.InputOutline
                            MaxLength={500}
                            Title="Video link"
                            maxRows={1}
                            minRows={1}
                            onBlur={() => this.props.onBlur && this.props.onBlur('VideoLink')}
                            {...getErrorMessage(this.props.MessageError, 'VideoLink')}
                            inputProps={{ multiline: false, name: 'VideoLink' }}
                            defaultValue={this.props.Model?.VideoLink}
                        />
                    </ControlBoxHalf>
                    <ControlBoxHalf sx={{ justifyContent: 'flex-end' }}>
                        <SubLocal.BasicTimePicker
                            title="Start time"
                            name="StartTime"
                            errorSx={{ width: 'auto' }}
                            {...getErrorMessage(this.props.MessageError, 'StartTime')}
                            onBlur={() => this.props.onBlur && this.props.onBlur('StartTime')}
                            defaultValue={this.props.Model?.StartTime ?? 0}
                        />
                    </ControlBoxHalf>
                    <ControlBoxHalf>
                        <SubLocal.BasicTimePicker
                            title="End time"
                            name="EndTime"
                            {...getErrorMessage(this.props.MessageError, 'EndTime')}
                            onBlur={() => this.props.onBlur && this.props.onBlur('EndTime')}
                            defaultValue={this.props.Model?.EndTime ?? 0}
                        />
                    </ControlBoxHalf>
                    <Grid item xs={12}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>{this.props.Actions}</Box>
                    </Grid>
                </Grid>
            </Paper>
        )
    }
}