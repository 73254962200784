import { Button } from "@mui/material"
import InputFormBase, { InputFormContext } from "../../../components/InputFormBase"
import FormBase from "./FormBase"
import { FC } from "react"
import { IClaimedVideoModel } from "../type"
import { FormValidator, SingleRuleValidate } from "../../../Utilities/ValidateModel"
import { ERoleType } from "../../../Utilities/Contants"
interface ICreateFormProps {
    onSubmit: (params: Partial<IClaimedVideoModel>) => Promise<void>
    Role?: ERoleType
}

function youtube_parser(url: string) {
    var regExp = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
    var match = url.match(regExp);
    return (match && match[1].length == 11) ? match[1] : false;
}

const FormValidate = new FormValidator<Partial<IClaimedVideoModel>>({
    UserId: { Rules: [{ rule: SingleRuleValidate.Required }] },
    NetworkId: { Rules: [{ rule: SingleRuleValidate.Required }] },
    Status: { Rules: [{ rule: SingleRuleValidate.Required }] },
    ClaimName: { Rules: [{ rule: SingleRuleValidate.Required }] },
    StartTime: { Rules: [{ rule: SingleRuleValidate.Required }] },
    EndTime: {
        Rules: [{
            rule: SingleRuleValidate.Custom,
            Value: (v) => {
                return !v || v <= 0
            },
            message: "TimeStart must be greater than 0"
        }, {
            rule: SingleRuleValidate.Custom,
            Value: (v, model: IClaimedVideoModel) => {
                console.log(model);

                return v * 1 <= (model.StartTime ?? 0) * 1
            },
            message: "TimeEnd must be greater than TimeStart"
        }]
    },
    VideoLink: {
        Rules: [{ rule: SingleRuleValidate.Required }, {
            rule: SingleRuleValidate.Custom,
            Value: (v, model: IClaimedVideoModel) => {
                const videoId = youtube_parser(v)
                if (videoId) {
                    model.VideoLink = 'https://youtu.be/' + videoId
                }
                return !videoId
            },
            message: "Invalid video link!"
        }]
    },
})


const UserFormValidate = new FormValidator<Partial<IClaimedVideoModel>>({
    NetworkId: { Rules: [{ rule: SingleRuleValidate.Required }] },
    ClaimName: { Rules: [{ rule: SingleRuleValidate.Required }] },
    StartTime: { Rules: [{ rule: SingleRuleValidate.Required }] },
    EndTime: {
        Rules: [{
            rule: SingleRuleValidate.Custom,
            Value: (v) => {
                return !v || v <= 0
            },
            message: "TimeStart must be greater than 0"
        }, {
            rule: SingleRuleValidate.Custom,
            Value: (v, model: IClaimedVideoModel) => {
                console.log(model);

                return v * 1 <= (model.StartTime ?? 0) * 1
            },
            message: "TimeEnd must be greater than TimeStart"
        }]
    },
    VideoLink: {
        Rules: [{ rule: SingleRuleValidate.Required }, {
            rule: SingleRuleValidate.Custom,
            Value: (v, model: IClaimedVideoModel) => {
                const videoId = youtube_parser(v)
                if (videoId) {
                    model.VideoLink = 'https://www.youtube.com/watch?v=' + videoId
                }
                return !videoId
            },
            message: "Invalid video link!"
        }]
    },
})

export const CreateForm: FC<ICreateFormProps> = (props) => {
    return <InputFormBase FormValidate={props.Role === ERoleType.ADMIN ? FormValidate : UserFormValidate} onSubmit={props.onSubmit}>
        <InputFormContext.Consumer>
            {({ onBlur, MessageError }) => (
                <FormBase
                    onBlur={onBlur}
                    MessageError={MessageError}
                    Role={props.Role}
                    IsForm={false}
                    Actions={
                        <Button type="submit" color="success" variant="contained">
                            Create
                        </Button>
                    }
                />
            )}
        </InputFormContext.Consumer>
    </InputFormBase>
}

interface IEditFormProps {
    Model: IClaimedVideoModel
    onSubmit: (params: Partial<IClaimedVideoModel>) => Promise<void>
    Role?: ERoleType
}

export const EditForm: FC<IEditFormProps> = (props) => {
    return <InputFormBase FormValidate={props.Role === ERoleType.ADMIN ? FormValidate : UserFormValidate} onSubmit={props.onSubmit}>
        <InputFormContext.Consumer>
            {({ onBlur, MessageError }) => (
                <FormBase
                    onBlur={onBlur}
                    MessageError={MessageError}
                    Role={props.Role}
                    IsForm={false}
                    Model={props.Model}
                    Title="Update"
                    Actions={
                        <Button type="submit" color="info" variant="contained">
                            Save
                        </Button>
                    }
                />
            )}
        </InputFormContext.Consumer>
    </InputFormBase>
}