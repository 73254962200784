import React, { FC, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from './Reduxes/types';
import { ERoleType } from '../../Utilities/Contants';

interface IRoleCompoenentReduxState {
    Role?: ERoleType
}
interface IRoleComponentProps {
    Components: { [key in ERoleType]?: React.ComponentType | JSX.Element }
}

function isComponentType(component: any): component is React.ComponentType {
    return typeof component === 'function';
}
type IProps = IRoleCompoenentReduxState & IRoleComponentProps
const RoleComponent: FC<IProps> = (props) => {
    const Element = props.Role ? props.Components[props.Role] ?? (() => <></>) : () => <></>
    return React.isValidElement(Element) ? Element : isComponentType(Element) ? <Element /> : <></>
}

const mapStateToProps = (state: RootState): IRoleCompoenentReduxState => ({
    Role: state.ClaimedVideoSlice.Role
})

export default connect(mapStateToProps)(RoleComponent)