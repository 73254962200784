import { Box, Button, CheckboxProps, Divider, Link, ListItemIcon, ListItemText, MenuItem, Typography } from '@mui/material'
import React, { Component, useCallback } from 'react'
import { FlexBox } from '../../components/LayouCompoent'
import { CreateTableTemplate, } from '../../components/TableTemplate/CreateTableTemplate'
import { EClaimedVideoStatus, ExtractObject, IClaimedVideoModel } from './type'
import { ConvertToTimespan, GetDataFromObject, } from '../../Utilities/helper'
import { TComponentReduxProps } from './Reduxes/MapAction'
import { CRUDPannel, ItemRowMenu, TItemRowMenuRender } from '../../components/TableTemplate'
import AudioFileIcon from '@mui/icons-material/AudioFile'
import { GlobalModalContext, IGlobalModalContext } from '../../components/GlobalModal'
import { CreateForm, EditForm } from './Forms/CreateForm'
import { PositionedMenu, SelectStandard } from '../../components/SubComponent'
import { ClaimedVideoStatusColors, ClaimedVideoStatusMenus, ERoleType } from '../../Utilities/Contants'
import { GridApiCommon, GridCellParams, GridFilterInputValueProps, GridState } from '@mui/x-data-grid'
import { ContextMenuTable, IRenderMenuState } from '../../components/ContextMenuTable'
import { Checkbox } from '@mui/material'
import { FormDelete } from './Forms/FormDelete'
import { ChangeStatusComfirm } from './Forms/ChangeStatusComfirm'
import { GridColDefConfig, ITableOption } from '../../components/TableTemplate/type'
import RoleComponent from './RoleComponent'
import { ContentCopy, Home } from '@mui/icons-material'
import { ApiAlertContext } from '../../components/ApiAlertContext'
import { DateTimeFilters } from '../../components/TableTemplate/Components'

interface IClaimedVideoProps {

}
interface IClaimedVideoState {
    // itemSelecteds: string[]
}

type TProps = IClaimedVideoProps & TComponentReduxProps
export default class ClaimedVideo extends Component<TProps, IClaimedVideoState> {
    constructor(props: TProps) {
        super(props)
        // this.itemSelecteds = []
        this.Table = this.createTable()
    }
    // itemSelecteds: string[]
    get Selected() { return Object.values(this.getItemsSelected()) }
    getTableConfig = () => {
        const option: GridColDefConfig<IClaimedVideoModel> = {
            Network: { flex: 1, headerName: 'Network', minWidth: 100 },
            Email: { flex: 1, headerName: 'User', minWidth: 150 },
            ClaimName: { flex: 1, headerName: 'Claim name', minWidth: 250 },
            VideoLink: {
                flex: 1, headerName: 'Video link', minWidth: 250, renderCell: (p) => {
                    const model = p.row as IClaimedVideoModel
                    return <Box component={Link} href={p.value ? p.value + `&t=${model.StartTime ?? 0}` : ''} target='_blank'>{p.value}</Box>
                }
            },
            TimeStamp: {
                flex: 1, headerName: 'Timestamp', minWidth: 150, renderCell: (p) => {
                    const data = p.row as IClaimedVideoModel
                    return `${ConvertToTimespan(data.StartTime ?? 0)} - ${ConvertToTimespan(data.EndTime ?? 0)}`
                }
            },
            DateCreated: {
                flex: 1, headerName: 'Created', minWidth: 100, renderCell: p => new Date(p.value).toDateString(),
                filterOperators: DateTimeFilters
            },
            Status: {
                flex: 1, headerName: 'Status', minWidth: 150,
                renderCell: (param) => {
                    this.refApiTable = param.api
                    const index = ClaimedVideoStatusMenus.findIndex(x => x.value === param.value)
                    return <RoleComponent
                        Components={{
                            ADMIN: <PositionedMenu defaultValue={index} onSubmitItem={(value: EClaimedVideoStatus) => this.onSubmitStatus(param.row, value)} data={ClaimedVideoStatusMenus} />
                            ,
                            USER: <>{ClaimedVideoStatusMenus[index].name}</>
                            ,
                            MANAGE: <>{ClaimedVideoStatusMenus[index].name}</>
                        }
                        }
                    />
                },
                filterOperators: [{
                    label: "Select",
                    value: "Select",
                    getApplyFilterFn: (filterItem) => {
                        if (!filterItem.field || !filterItem.value || !filterItem.value) {
                            return null;
                        }

                        return (params: GridCellParams): boolean => {
                            return params.value === filterItem.value;
                        };
                    },
                    InputComponent: (props: GridFilterInputValueProps) => {
                        const Onchange = useCallback<React.ChangeEventHandler<HTMLSelectElement>>(
                            (event) => {
                                props.applyValue({ ...props.item, value: event.target.value })
                            },
                            [props],
                        )
                        return <SelectStandard data={ClaimedVideoStatusMenus.map(x => x.value)} title='Value' onChange={Onchange} defaultValue={props.item.value} />
                    }
                }]
            }
        }
        switch (this.props.ClaimedVideoSlice.Role) {
            case ERoleType.USER:
                delete option.Email
                break
            case ERoleType.MANAGE:
            case ERoleType.ADMIN:
            default:

                break;
        }
        return option;
    }

    createTable = () => {
        const Table = CreateTableTemplate<IClaimedVideoModel>("Client", {
            getRowId: (x: IClaimedVideoModel) => x.Id,
            config: this.getTableConfig(),
            MenuField: 'Network'
        })
        return Table
    }

    onSubmitStatus = async (item: IClaimedVideoModel, value: EClaimedVideoStatus) => {
        if (item.Status === value) return
        await this.props.UpdateStatuse(item.Id, value)
    }

    contentModal = {
        create: (context: IGlobalModalContext) => <CreateForm Role={this.props.ClaimedVideoSlice.Role} onSubmit={async (model) => {
            context.closeModal()
            await this.props.Add(model)
        }} />,
        edit: (context: IGlobalModalContext, data: IClaimedVideoModel) => <EditForm Role={this.props.ClaimedVideoSlice.Role} Model={data} onSubmit={async (model) => {
            await this.props.Update(data.Id, model)
            context.closeModal()
        }} />,
        delete: (context: IGlobalModalContext, data: IClaimedVideoModel) => <FormDelete
            onClose={context.closeModal}
            onSubmit={() => { context.closeModal(); this.props.Remove(data.Id) }} />,
        ChangeStatus: (context: IGlobalModalContext, value: EClaimedVideoStatus) => <ChangeStatusComfirm
            content={`${this.Selected.length} item(s) will be changed`}
            onClose={context.closeModal}
            onSubmit={() => {
                context.closeModal(); this.props.UpdateStatuses(Array.from(Object.keys(this.getItemsSelected() ?? {})), value)
            }} />,
    }
    renderRowMenu = ({ data, children }: TItemRowMenuRender<IClaimedVideoModel>) => (
        <GlobalModalContext.Consumer>
            {(context) => (
                <ItemRowMenu
                    disableEdit={data.Status === EClaimedVideoStatus.Approved || data.Status === EClaimedVideoStatus.Pending}
                    onEdit={(e) => { context.showModal({ content: () => this.contentModal.edit(context, data), isBlockBackdropClick: true }) }}
                    onDelete={() => context.showModal({ content: () => this.contentModal.delete(context, data) })}
                >
                    {children}
                </ItemRowMenu>
            )}
        </GlobalModalContext.Consumer>
    )
    getData = () => GetDataFromObject<IClaimedVideoModel>(this.props.ClaimedVideoSlice.data.entities)
    GridState?: GridState
    refApiTable: GridApiCommon | undefined
    onStateChange = (GridState: GridState) => {
        this.GridState = GridState
    }

    getItemsSelected = () => {
        if (this.GridState) {
            const rows = this.GridState.rows
            return this.GridState.rowSelection.reduce<{ [Id: string]: IClaimedVideoModel }>((a, x) => {
                a[x] = rows.dataRowIdToModelLookup[x] as IClaimedVideoModel
                return a

            }, {})
        }
        return {}
    }

    IsSelected = () => !!this.Selected.length
    onChangeStatuses = async (context: IGlobalModalContext, value: EClaimedVideoStatus, event: React.MouseEvent<HTMLElement>) => {
        context.showModal({ content: () => this.contentModal.ChangeStatus(context, value) })
        // await this.props.UpdateStatuses(Array.from(Object.keys(this.getItemsSelected() ?? {})), value)
    }
    renderMenu = (context: IGlobalModalContext, state: IRenderMenuState) => {
        return [
            <MenuItem key={"keyColor" + "Copy"} disabled={!this.IsSelected()} onClick={this.onCopy}>
                <ListItemIcon>
                    <ContentCopy style={{ color: 'blueviolet' }} fontSize="small" />
                </ListItemIcon>
                <ListItemText>Copy</ListItemText>
            </MenuItem>
            ,
            <Divider key={'div1'} />
            ,
            ...(Object.keys(EClaimedVideoStatus).map((x, i) => {
                const Temp = ClaimedVideoStatusColors[x]
                return <MenuItem key={"keyColor" + i} disabled={!this.IsSelected()} onClick={(e) => this.onChangeStatuses(context, x as EClaimedVideoStatus, e)}>
                    <ListItemIcon>
                        <Temp.icon style={{ color: Temp.color }} fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{x}</ListItemText>
                </MenuItem>
            }))

        ]
    }
    componentDidMount(): void {
        // setTimeout(() => {
        //     this.setState({ showTable: true })
        // }, 2000);
    }
    Table: ReturnType<typeof this.createTable>
    renderTable = (context: IGlobalModalContext) => {
        console.log('dadasdasdasd render');

        const { Table } = this
        return <Table
            CRUDPannel={() => (<CRUDPannel
                Create={this.contentModal.create(context)}
            />)}
            ItemRowMenu={this.renderRowMenu}
            data={this.getData()}
            InnerProps={{
                // rowSelectionModel: this.Selected,
                onStateChange: this.onStateChange,
                // onRowSelectionModelChange: (rowSelectionModel, details) => {
                //     this.itemSelecteds = rowSelectionModel as string[]
                // },
                loading: this.props.ClaimedVideoSlice.tableLoading,
                // slots: {
                //     baseCheckbox: React.forwardRef<any, CheckboxProps>((p: CheckboxProps, ref) => {
                //         return p.inputProps && p.inputProps["aria-label"] !== "Select all rows"
                //             ? <Checkbox ref={ref} {...p} disabled={this.props.ClaimedVideoSlice.tableLoading} />
                //             : <></>
                //     }),
                // },
                initialState: {
                    sorting: {
                        sortModel: [{ field: 'DateCreated', sort: 'desc' }]
                    },
                },
                checkboxSelection: true,
            }}
        />
    }
    Keys = ["Network", "Email",
        "ClaimName",
        "ClaimLink",
        "VideoLink",
        "TimeStamp",
        "DateCreated",
        "Status"]
    generateString = (model: ExtractObject | IClaimedVideoModel) => {
        const values = this.Keys.map(key => (model as ExtractObject)[key])
        const indexTimeStamp = this.Keys.findIndex(x => x === "TimeStamp")
        const m = model as IClaimedVideoModel
        values[indexTimeStamp] = `${ConvertToTimespan(m.StartTime ?? 0)} - ${ConvertToTimespan(m.EndTime ?? 0)}`
        return values.join("\t")
    }
    onCopy = () => {
        if (!this.GridState || this.GridState.rowSelection.length < 1) {
            ApiAlertContext.ApiAlert?.PushWarning("No items selected!")
            return
        }
        const contents = this.GridState.rowSelection.map(id => this.generateString(this.GridState?.rows.dataRowIdToModelLookup[id] as any))
        contents.splice(0, 0, this.Keys.join("\t"))
        navigator.clipboard.writeText(contents.join("\r\n"))
    }


    renderMenuTable = (context: IGlobalModalContext) => {
        return <RoleComponent
            Components={{
                ADMIN: () => <ContextMenuTable key={'table-key'} renderMenu={(state) => this.renderMenu(context, state)}>
                    {this.renderTable(context)}
                </ContextMenuTable>,
                MANAGE: () => this.renderTable(context),
                USER: () => this.renderTable(context)
            }}
        />
    }
    render() {
        return (
            <FlexBox sx={{ flex: 1, flexDirection: 'column', width: '100%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', padding: '12px', gap: '8px', justifyContent: 'space-between' }}>
                    <FlexBox sx={{ alignItems: 'center' }}>
                        <AudioFileIcon color="info" />
                        <Typography variant="h5">Manual Claim</Typography>
                    </FlexBox>
                    <Button variant='outlined' startIcon={<Home />} {...{ component: 'a', href: '/' }}>
                        Home
                    </Button>
                </Box>
                <FlexBox sx={{ flexDirection: "column", flex: 1, padding: '10px' }}>
                    <GlobalModalContext.Consumer>
                        {(context) => this.renderMenuTable(context)}
                    </GlobalModalContext.Consumer>
                </FlexBox>
            </FlexBox>
        )
    }
}
