import React, { Component, ReactNode } from 'react'
import { IconButton, Tooltip } from '@mui/material'
import { Delete, Edit } from '@mui/icons-material'
import './index.css'

interface IItemRowMenuProps {
  onEdit?: (e: React.MouseEvent) => void
  onDelete?: (e: React.MouseEvent) => void
  disableEdit?: boolean
}

export type TItemRowMenuRender<T> = { data: T; children: ReactNode }

export class ItemRowMenu extends Component<React.PropsWithChildren<IItemRowMenuProps>> {
  render() {
    return (
      <div className='wrap-title-video' onClick={e => { e.preventDefault(); e.stopPropagation() }}>
        <span className='title'>{this.props.children}</span>
        <div className='container-action'>
          {this.props.onEdit && this.props.disableEdit !== true && (
            <Tooltip title='Edit'>
              <IconButton onClick={this.props.onEdit} >
                <Edit color='info' fontSize='small' />
              </IconButton>
            </Tooltip>
          )}
          {this.props.onDelete && (
            <Tooltip title='Delete'>
              <IconButton onClick={this.props.onDelete}>
                <Delete color='error' fontSize='small' />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
    )
  }
}
export default ItemRowMenu
