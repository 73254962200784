import { FC } from 'react'
import { TDispatchRedux, TStateRedux } from '../../../components/HocLazy/type'
import { AppDispatch, RootState } from './types'
import { CreateHocLazy } from '../../../components/HocLazy'
import ClaimedVideo from '..'
import { ClaimedVideoSlice, ISliceStateRedux } from './Slice'
import { fetchSliceThunk } from './Thunks'
import { connect } from 'react-redux'
import { EClaimedVideoStatus, IClaimedVideoModel } from '../type'
import { Update } from '@reduxjs/toolkit'
import ClaimedVideoService from './ClaimedVideoService'

export interface ClaimedVideoReduxState extends TStateRedux {
    ClaimedVideoSlice: ISliceStateRedux
}

export interface ClaimedVideoReduxDispatch extends TDispatchRedux {
    Add: (item: Partial<IClaimedVideoModel>) => void
    Update: (id: string, item: Partial<IClaimedVideoModel>) => void
    Remove: (id: string) => void
    UpdateStatuses: (ids: string[], status: EClaimedVideoStatus) => void
    UpdateStatuse: (id: string, status: EClaimedVideoStatus) => void
}

const mapStateToProps = (state: RootState): ClaimedVideoReduxState => ({
    status: state.ClaimedVideoSlice.status,
    ClaimedVideoSlice: state.ClaimedVideoSlice,
})


const TableLoadingRequest = async (dispatch: AppDispatch, action: () => Promise<void>) => {
    dispatch(ClaimedVideoSlice.actions.TableLoading(true))
    try {
        await action()
    } finally {
        dispatch(ClaimedVideoSlice.actions.TableLoading(false))
    }
}

// ######### Admin maping #########
const MapDispatchToProps = (dispatch: AppDispatch): ClaimedVideoReduxDispatch => {
    return {
        FetchData: () => {
            return dispatch(fetchSliceThunk({}))
        },
        async Add(item) {
            TableLoadingRequest(dispatch, async () => {
                const data = await ClaimedVideoService.Create(item as IClaimedVideoModel)
                dispatch(ClaimedVideoSlice.actions.Add(data))
            })
        },
        async Update(id, item) {
            TableLoadingRequest(dispatch, async () => {
                const data = await ClaimedVideoService.Update(id, item as IClaimedVideoModel)
                dispatch(ClaimedVideoSlice.actions.Update({
                    id: id,
                    changes: data
                }))
            })
        },
        async Remove(id) {
            TableLoadingRequest(dispatch, async () => {
                const data = await ClaimedVideoService.Remove(id)
                dispatch(ClaimedVideoSlice.actions.Remove(data))
            })
        },
        async UpdateStatuses(ids, status) {
            TableLoadingRequest(dispatch, async () => {
                const data = await ClaimedVideoService.UpdateStatus(ids, status)
                dispatch(ClaimedVideoSlice.actions.UpdateRange(ids.map<Update<Partial<IClaimedVideoModel>>>(x => {
                    return { id: x, changes: { Status: status } }
                })))
            })
        },
        async UpdateStatuse(id, status) {
            const data = await ClaimedVideoService.UpdateStatus([id], status)
            dispatch(ClaimedVideoSlice.actions.UpdateRange([{ id, changes: { Status: status } }]))
        },
    }
}

export type TComponentReduxProps = ClaimedVideoReduxState & ClaimedVideoReduxDispatch

const ClaimedVideoHoc = CreateHocLazy(ClaimedVideo)
export const ClaimedVideoContainer = connect(mapStateToProps, MapDispatchToProps)(ClaimedVideoHoc)