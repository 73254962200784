import { EntityState } from "@reduxjs/toolkit";

export enum LazyStatus {
    Loading = "Loading",
    Loaded = "Loaded",
    Error = "Error",
}
export interface ISliceBase<TModel> {
    status: LazyStatus;
    requestId?: string;
    data: EntityState<TModel>;
}
export interface IModelCombobox {
    name: string
    value: string
}